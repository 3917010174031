import TabHeaderComponent from './component/tab-header/tab-header.component.vue';

export default {
    name: "tab-warpper.component",
    components: {
        'tab-header-component': TabHeaderComponent
    },
    props: {
        tabWrapperHeaders: {
            type: Array,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        dynamicClass: {
            type: String,
            required: false
        },
        isInlineActive: {
            type: Boolean,
            required: true,
            default: true
        },
        isFlexTabWrapper: {
            type: Boolean,
            required: true,
            default: false
        },
        contentCount: {
            type: Number
        },
        isCustom: {
            type: String,
            required: true,
        },
        data: {
            type: Array,
            required: false
        }
    },
    data(){
        return{
            active: 1
        }
    },
    methods:{
        showContentIndex(event){
            this.active = event;
            this.$emit('avtiveTab',this.active)
        }
    }
}
