export default {
    name: "tab-header.component",
    props:{
        tabHeaders: {
            type: Array,
            required: true
        },
        isInline: {
            type: Boolean,
            default: true,
            required: true
        }
    },
    data(){
        return{
            active: 1
        }
    },
    methods: {
        changeHeader(e){
            this.active = e;
            this.$emit('changeTitle', this.active)
        }
    }
}
