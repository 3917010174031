import {mapGetters} from "vuex";

export default {
    name: "pagination-component",
    props: {
        pageSize: {
            type: Number,
            required: true
        },
        pageNumber: {
            type: Number,
            required: true
        },
        totalCount: {
            type: Number,
            required: true
        }
    },
    data(){
        return{
            currentPage: 1,
            myArr: []
        }
    },
    computed: {
        pagination(){
            return Math.ceil(this.totalCount / this.pageSize)
        },
        isLastPage(){
            return this.pageNumber === this.pagination;
        },
        ...mapGetters({
            local: '_getLocal'
        })
    },
    methods:{
        changePages(item){
            this.$emit('changePage', item)
        },
        previosPage(){
            this.currentPage = this.pageNumber
            if(this.pageNumber === 1){
                this.changePages(this.currentPage)
            }else {
                this.changePages(this.currentPage - 1)
            }
        },
        nextPage(){
            for (let i=1; i<= this.pagination; i++){
                this.myArr.push(i)
            }
            this.currentPage = this.pageNumber + 1
            if(this.currentPage > this.myArr.length){
                return false
            }else{
                this.changePages(this.currentPage)
            }
        }
    }
}