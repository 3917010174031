<style lang="scss" src="./tab-wrapper.component.scss"></style>

<template>
  <div v-if="isCustom == 'about'" :class="isFlexTabWrapper ? 'tabs_wrapper' :'tab_wrapper'">
    <tab-header-component :isInline="isInlineActive" :tabHeaders="tabWrapperHeaders" @changeTitle="showContentIndex"/>
    <div :class="isFlexTabWrapper ? 'tabs_wrapper-first' : 'tab_wrapper-inner mb-35'" >
      <transition-group name="slide-fade"
                        mode="out-in">
      <div :class="isFlexTabWrapper ? 'tabs_wrapper-first__content' : 'tab_wrapper-inner__content'"  v-for="(item,index) in data" v-show="active === index+1" :key="index">
        <slot name="item" :item="item"/>
      </div>
      </transition-group>
    </div>
  </div>
  <div v-if="isCustom == 'project'" :class="isFlexTabWrapper ? 'tabs_wrapper' :'tab_wrapper'">
    <tab-header-component :isInline="isInlineActive" :tabHeaders="tabWrapperHeaders" @changeTitle="showContentIndex"/>
    <div :class="isFlexTabWrapper ? 'tabs_wrapper-first' : 'tab_wrapper-inner mb-35'" >
      <transition-group name="slide-fade"
                        mode="out-in">
        <div :class="isFlexTabWrapper ? 'tabs_wrapper-first__content' : 'tab_wrapper-inner__content'"  v-for="i in contentCount" v-show="active === i" :key="i">
          <slot :name="'slot'+i"/>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script lang="js" src="./tab-wrapper.component.js"></script>
