<style lang="scss" src="./pagination.component.scss"></style>

<template>
  <nav class="pagination-section pt-1 pl-45 pr-45 pb-1">
      <ul>
        <li @click="previosPage" v-if="pageNumber !== 1">
          <a href="#">{{local.pagination.previos}}</a>
        </li>
        <li v-for="(page, index) in pagination" :key="index" @click="changePages(page)">
          <a :class="pageNumber === page ? 'active' : ''" href="#">{{page}}</a>
        </li>
        <li @click="nextPage" v-if="!isLastPage">
          <a href="#">{{local.pagination.next}}</a>
        </li>
      </ul>
  </nav>
</template>

<script lang="js" src="./pagination.component.js"></script>