<style lang="scss" src="./projects.component.scss"></style>

<template>
  <div class="project mt-80">
    <div class="project_bg"></div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <tab-wrapper-component isCustom="project" @avtiveTab="showActiveTab" :isFlexTabWrapper="true" :isInlineActive="true" :tabWrapperHeaders="local.tabHeader" :contentCount="slotCount">
            <template #slot1>
              <div class="project_finished">
                <div class="row">
                  <div class="col-lg-4 col-md-6" v-for="(project,index) in projects" :key="index">
                    <card-component class="project_finished-content mt-32">
                      <div class="project_finished-content__images" v-if="local && local.projectDetails">
                        <img :src="project.mainPicture" alt="">
                        <button type="button" @click="showMore(project.id)" class="py-12 px-24 more">{{local.projectDetails.btnText}}</button>
                      </div>
                      <div class="project_finished-content__text">
                        <p class="ml-20">{{project.name}}</p>
                      </div>
                    </card-component>
                  </div>
                </div>
                <div class="project_finished_more">
                  <pagination-component @changePage="pagination" :totalCount="totalCount" :pageNumber="page.pageNumber" :pageSize="page.pageSize"/>
                </div>
              </div>
            </template>
            <template #slot2>
              <div class="project_finished">
                <div class="row">
                  <div class="col-lg-4 col-md-6" v-for="(project,index) in projects" :key="index">
                    <card-component class="project_finished-content mt-32">
                      <div class="project_finished-content__images" v-if="local && local.projectDetails">
                        <img :src="project.fileUrl" alt="">
                        <button type="button" @click="showMore(project.id)" class="py-12 px-24 more">{{local.projectDetails.btnText}}</button>
                      </div>
                      <div class="project_finished-content__text">
                        <p class="ml-20">{{project.name}}</p>
                      </div>
                    </card-component>
                  </div>
                </div>
              </div>
              <div class="project_finished_more">
                <pagination-component @changePage="paginate" :totalCount="totalCount" :pageNumber="page.pageNumber" :pageSize="page.pageSize"/>
              </div>
            </template>
          </tab-wrapper-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./projects.component.js"></script>
