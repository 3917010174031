import TabWrapperComponent from "@/core/shared/tab/tab-wrapper.component.vue";
import CardComponent from "@/core/shared/card/card.component.vue";
import {mapGetters} from "vuex";
import PaginationComponent from "@/core/shared/pagination/pagination.component.vue";

export default {
    name: "projects.component",
    components: {
      'tab-wrapper-component': TabWrapperComponent,
      'card-component': CardComponent,
      'pagination-component': PaginationComponent
    },
    data(){
        return{
            slotCount: 2
        }
    },
    computed: {
        ...mapGetters({
            projects: 'proects/_getProjects',
            notFinishedProjects: 'proects/_getNotFinishedProjects',
            page: 'proects/_getPage',
            totalCount: 'proects/_getTotalCount',
            local: '_getLocal',
        })
    },
    methods:{
        showMore(index){
            this.$router.push({path: `/project/${index}`})
        },
        showMoreData(){
            this.$store.dispatch('proects/getProjects')
        },
        pagination(item){
            this.$store.dispatch('proects/setPagination',item).then(()=>{
                this.getProjects(true);
            })
        },
        paginate(item){
            this.$store.dispatch('proects/setPagination',item).then(()=>{
                this.getProjects(false);
            })
        },
        showActiveTab(item){
            if(item === 1){
                this.$store.commit('proects/SET_PROJECTS_PAGINATION',1)
                this.$store.dispatch('proects/getProjects',true)
            }else if(item === 2){
                this.$store.commit('proects/SET_PROJECTS_PAGINATION',1)
                this.$store.dispatch('proects/getProjects',false)
            }
        },
        getProjects(isTrue) {
            this.$store.dispatch('proects/getProjects',isTrue);
        }
    },
    created() {
        this.getProjects(true);
    }
}
