<style lang="scss" src="./tab-header.component.scss"></style>

<template>
  <div :class="isInline ? 'tabs py-0 mb-35' : 'tab py-0 mb-35'">
    <ul :class="isInline ? 'tabs__header' : 'tab__block'">
      <li
          v-for="(title,index) in tabHeaders"
          :key="index"
          @click="changeHeader(index+1)" :class="active === index+1 ? 'avtiveItem' : '' ">
        {{ title }}
      </li>
    </ul>
  </div>
</template>

<script lang="js" src="./tab-header.component.js"></script>

